
.ws-container {
    margin-top: 5%;
    width: 40%;
    flex-shrink: 0;
    padding: 20px;
    border-radius: 10px;
    overflow: auto;
    max-height: 700px;
}

.specs-container {
    padding-top: 5%;

}

  .section-title-small {
    font-size: 1.25rem;
    font-weight: bold;
    padding-bottom: 10px;
    color: var(--text-color); /* Theme-based text color */
    margin: 10px 0px;
  }
  
  .param-container {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid var(--dropdown-border);
  }
  
  .param-label {
    font-family: 'Fira Code', monospace;
    font-size: 0.9rem;
    color: var(--post-color); 
    padding: 5px 0px;
    border-radius: 4px;
    display: flex;
  }
  
  .param-description {
    font-size: 0.95rem;
    line-height: 1.4rem;
    color: var(--tertiary-color); /* Theme-based tertiary color */
    margin-top: 5px;
    margin-bottom: 15px;
  }
  
  .required-label {
    padding-right: 2px;
    padding-left: 4px;
    font-size: 0.7rem;
    color: red;  
    margin-bottom: 5px;
}
  
  .default-label {
    font-size: 0.9rem;
    margin-left: 10px;
    color: var(--secondary-text-color); /* Theme-based secondary text color */
  }