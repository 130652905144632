.hljs-string {
    color: #00a67d;
    padding-right: 5px;
}

.hljs-variable {
    color: #df3079;
    padding-right: 5px;
}
.code-block {
    position: relative;
    background-color: black;
    color: white;
    border-radius: 10px;
    padding: 16px 16px;
    height: 100%;
    font-size: 15px;
    line-height: 24px;
}

.url {
    font-weight: bold;
    padding-right: 5px;
}

.copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #4d9cff;
    cursor: pointer;
    font-size: 16px;
  }
  
  .copy-button:hover {
    color: #ffffff;
  }
  
  /* Notification that appears when the code is copied */
  .copied-notification {
    position: absolute;
    top: 10px;
    right: 50px;
    color: green;
    font-size: 0.9rem;
    font-weight: bold;
  }

.code-block-endpoints {
  color: rgb(224, 224, 224);
  background: rgb(18, 18, 18);
  text-shadow: rgba(0, 0, 0, 0.3) 0px 1px;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 0.8rem;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: break-word;
  overflow-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 20px;
  margin: 0.5em 0px;
  overflow: auto;
  border-radius: 10px;
}

.code-container {
  margin-top: 20px;
    width: 40%;
    flex-shrink: 0;
    padding: 20px;
    border-radius: 10px;
    overflow: auto;
    max-height: 500px;
}